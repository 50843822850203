<template lang="">
  <div
    v-if="
      user.permissions.indexOf(permissionsAll.project_list) >= 0 ||
        user.person.types_person.id === 3
    "
  >
    <ProjectCard />
  </div>
</template>
<script>
import { ProjectCard } from "../../components/project";
import permissions from "../../helpers/permissions";
import { mapState } from "vuex";
export default {
  name: "ProjectView",
  components: {
    ProjectCard,
  },
  data() {
    return {
      permissionsAll: permissions,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>
